/* src/App.css */
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f4f4f9;
}

.App {
  text-align: center;
}

nav {
  background-color: #333;
  padding: 1em;
}

nav h1 {
  color: #fff;
  margin: 0;
  display: inline;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
}

nav ul li a:hover {
  text-decoration: underline;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em;
}

.header {
  background-color: #4CAF50;
  color: white;
  padding: 2em 0;
}

.header h2 {
  margin: 0;
}

.content {
  text-align: left;
  padding: 2em;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.content h2 {
  margin-top: 0;
}
