/* Navbar container with white background */
nav {
    background-color: #ffffff !important; /* White background */
    padding: 3em; /* Padding around the navbar */
    display: flex; /* Use flexbox for alignment */
    justify-content: space-between; /* Space out items */
    align-items: center; /* Center items vertically */
}

/* New left-aligned heading */
nav .left-heading {
    color: #000000 !important; /* Gray text color */
    margin-left: 22.5%; /* Left margin */
    flex: 0; /* Take available space to push other items to the right */
    font-size: 1.2em;
    flex-grow: 0;
}

/* Navbar list container */
nav ul {
    list-style: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
    display: flex; /* Display list items in a row */
    justify-content: flex-end; /* Align items to the right */
    align-items: center; /* Center items vertically */
    margin: 0;
    margin-right: 25%;
}

/* Navbar list items */
nav ul li {
    margin: 0 15px; /* Horizontal spacing between list items */
}

/* Navbar links with gray text */
nav ul li a {
    color: #6d6d6d !important; /* Gray text color */
    text-decoration: none; /* Remove default link underline */
    font-size: 1.2em; /* Increase font size */
}

/* Navbar link hover effect */
nav ul li a:hover {
    text-decoration: underline; /* Underline text on hover */
}
