@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;700&display=swap');

.home-container {
    text-align: left;
    line-height: 1; /* Double spacing */
    color: rgb(0, 0, 0); /* Font color */
    font-family: 'Crimson Text', sans-serif;
    margin-left: 20%; /* Assuming you want to use the 'Crimson Text' font from Google Fonts */
    margin-right: 20%;
    font-size:1.5em;
}
